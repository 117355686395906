<template>
    <div class="thanks">
      <div class="hero hero-order">
    <div class="container">
      <p style="font-size: 14px;">
        <a href="/" style="color: inherit; text-decoration: none;">
          {{$t('Home') }}
        </a>
        &nbsp;
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.85742 10.3641L3.44858 6L7.85742 1.63594L6.2047 -7.22428e-08L0.143136 6L6.2047 12L7.85742 10.3641Z" fill="#AFAFAF" />
        </svg>
        &nbsp;
        <a href="/thanks" style="color: #225476; text-decoration: none;">
          {{ $t('Thanks For You') }}
        </a>
      </p>
    </div>
  </div>
    
    <div class="container">
        <div class="tahnks-element bg-gray">
          <div class="row">
            <div class="col-md-6 mb-3 text-center">
              <img src="@/assets/img/global/thanks-page.png" alt="">
            </div>
            <div class="col-md-6">
              <h5 class="title">
                {{ $t('Thank you for choosing us !') }}
              </h5>
              <p>{{ $t('You can contact us if you need any further assistance.') }}</p>
              <div class="d-flex align-items-center justify-content-start">
                <router-link class="btn-main" to="/">
                  {{ $t('Home') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
name: 'Thanks Contact',
components: {
 
},
}
</script>